export default {
  zh: {
    'site.title': '加拿大甘肃总商会',
    'navbar.about': '关于我们',
    'navbar.gallery': '照片集锦',
    'navbar.blog': '商会动态',
    'navbar.contact': '联系我们',
    'navbar.reports': '相关报道',
    'footer.home': '首页',
    'footer.about': '关于我们',
    'footer.admin': '管理网页',
    'footer.latest': '最新动态',
    'footer.contact': '联系我们',
    'home.readMore': '阅读更多',
    'blog.keepReading': '阅读全文 →',
    'tags.browseAll': '查看所有标签',
    'page.previousPage': '← 上一页',
    'page.nextPage': '下一页 →',
  },
  en: {
    'site.title': 'CGFCC',
    'navbar.about': 'About',
    'navbar.gallery': 'Gallery',
    'navbar.blog': 'Blog',
    'navbar.contact': 'Contact',
    'navbar.reports': 'Reports',
    'footer.home': 'Home',
    'footer.about': 'About',
    'footer.admin': 'Admin',
    'footer.latest': 'Latest Stories',
    'footer.contact': 'Contact',
    'home.readMore': 'Read more',
    'blog.keepReading': 'Keep Reading →',
    'tags.browseAll': 'Browse all tags',
    'page.previousPage': '← Previous Page',
    'page.nextPage': 'Next Page →',
  },
}
